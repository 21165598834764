<template>
  <div>
    <div class=".container hero">
      <h1> Website is currently under construction 🛠️</h1>
    </div>
    <div class="container text-center">
      <div class="row">
        <div class="col">
        </div>
        <div class="col">
          <div class=".container details">
            <div style="font-weight: bold"> Cod unic de inregistrare</div> RO44336771 <br>
            <div style="font-weight: bold"> Nr ordine in registrul comertului </div> J40/15390/2023 <br>
            <div style="font-weight: bold"> Address </div> București, sectorul 2, str. Grigore Ionescu, nr. 63 <br>
            <div style="font-weight: bold"> Email </div> office@gwd.ro
          </div>
        </div>
        <div class="col">
        </div>
      </div>
      <div class="row images">
        <div class="col">
          <img src="../assets/1.png" class="rounded float-start" alt="...">
        </div>
        <div class="col">
          <img src="../assets/2.png" class="rounded mx-auto d-block" alt="...">
        </div>
        <div class="col">
          <img src="../assets/3.png" class="rounded mx-auto d-block" alt="...">
        </div>
      </div>
      <div class="row images">
        <div class="col">
          <img src="../assets/4.png" class="rounded float-start" alt="...">
        </div>
        <div class="col">
          <img src="../assets/5.png" class="rounded mx-auto d-block" alt="...">
        </div>
        <div class="col">
          <img src="../assets/6.png" class="rounded mx-auto d-block" alt="...">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hero {
  margin-top: 5vh;
  font-weight: bold;
}

.details {
  text-align: left;
  margin-top: 5vh;
  font-size: larger;
}

.images {
  margin-top: 3vh;
}

img {
  max-width: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
